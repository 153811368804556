.grid-products-container{
    background: var(--product-container-color);
}
.discount-banner h5{
    letter-spacing: -1px;
    word-spacing: -1px;
    font-weight: 400;
}
.discount-banner p{
    color: rgb(83, 83, 83) !important;
}
.discount-grid {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: .5rem;
}
@media (min-width: 320px) and (max-width: 424px) {
    .discount-grid {
    grid-template-columns: repeat(2,1fr);
}
}
@media (min-width: 425px) and (max-width: 575px) {
    .discount-grid {
    grid-template-columns: repeat(2,1fr);
}
}
@media (min-width: 576px) and (max-width: 767px) {
    .discount-grid {
        grid-template-columns: repeat(3,1fr);
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .discount-grid {
        grid-template-columns: repeat(4,1fr);
    }
}
@media (min-width: 991px) and (max-width: 1023px) {
    .discount-grid {
        grid-template-columns: repeat(4,1fr);
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {
    .discount-grid {
        grid-template-columns: repeat(3,1fr);
    }
}