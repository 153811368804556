.main-home{
    opacity: 1;
    animation: loader-transition 1s linear forwards;
    -webkit-animation: loader-transition2 1s linear forwards;
}
@keyframes loader-transition2 {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}