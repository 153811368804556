.nav-hover{
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}
.slider3-text-container{
    box-shadow: 0 0 5px #e3e3e3;
}
.slider3-img{
    height: 465px !important;
}

.category-title span {
    /* font-size: 15px; */
    color: rgb(255, 255, 255);
}
.category-heading span {
    /* font-size: 13px; */
    font-weight: 400;
    text-align: start;
}
.category-items a {
    font-size: 12px;
}
.slider2-category::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
}
.slider2-category::-webkit-scrollbar-thumb {
    border-radius: 15px;
}
.slider-category-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 17px !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 17px !important;
}
.slider3-items {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 5;
    width: fit-content;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    border-radius: 5px;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.category-heading-container:hover .slider3-items{
    visibility: visible;
    opacity: 1;
    z-index: 5;
}

.slider3-items span,.slider3-items a,.category-heading-container svg{
    font-size: 14px;
}
.banner-slider2 img{
    height: 100%;
}
@media (min-width: 320px) and (max-width: 424px) {
    .category-heading-container:hover .slider3-items {
        visibility: visible;
        opacity: 1;
        z-index: 5;
        left: 30%;
        top: 30px;
    }
    .banner-slider2 img{
        height: 100%;
    }
    .slider3-img{
        height: 100% !important;
    }
}
@media (min-width: 425px) and (max-width: 575px) {
    .category-heading-container:hover .slider3-items {
        visibility: visible;
        opacity: 1;
        z-index: 5;
        left: 30%;
        top: 30px;
    }
    .banner-slider2 img{
        height: 100%;
    }
    .slider3-img{
        height: 100% !important;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .category-heading-container:hover .slider3-items {
        visibility: visible;
        opacity: 1;
        z-index: 5;
        left: 30%;
        top: 30px;
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .slider3-img{
        height: 300px !important;
    }
}
@media (min-width: 991px) and (max-width: 1023px) {}
@media (min-width: 1024px) and (max-width: 1439px) {}
