
.breadcumb-container a {
    font-size: 13px;
    font-weight: 500;
    color: var(--secondary-text-color);
    font-family: 'Dosis', sans-serif;
}


@media (min-width: 320px) and (max-width: 424px) {
    .breadcumb-container a {
        font-size: 13px;
    }
}
@media (min-width: 425px) and (max-width: 575px) {
    .breadcumb-container a {
        font-size: 13px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {}
@media (min-width: 768px) and (max-width: 990px) {}
@media (min-width: 991px) and (max-width: 1023px) {}
@media (min-width: 1024px) and (max-width: 1439px) {}