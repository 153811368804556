.feature-card2-text-container {
    width: 100%;
    color: var(--primary-text-color);
}
.product-container-color{
    background-color: var(--product-container-color)!important;
}
.feature-card2-text-container h3 {
    font-size: 2rem;
    font-weight: 700;
}