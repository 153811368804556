

.nav-menu-layout6 {
    position: fixed;
    height: 100vh;
    width: 80%;
    left: 0;
    top: 0;
    background: white;
    z-index: 1;
    
}
