.cart:hover .cart-hover-item{
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.cart-hover-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0%;
    width: 300px;
    box-shadow: 0 0 5px #dadada;
    padding: 1rem;
    font-size: 14px;
}