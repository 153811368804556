.navBar3 span,.navBar3 button,.navBar3 svg,.navBar3 a{
    font-size: 14px;
    color: var(--primary-text-color) !important;
}
.show-drawer{
    right: 0 !important;
}
.navbar3{
    background: var(--product-container-color) !important; 
}


.navbar3-drawer {
    position: fixed;
    transition: .4s;
    top: 0;
    right: -340px;
    width: 340px;
    background: var(--light-color);
    height: 100vh;
    z-index: 2;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
.navBar3-layout {
    background: #00000059;
    position: fixed;
    top: 0;
    z-index: 2;
    height: 100vh;
    width: 100%;
    left: 0;
}
ul.nav-menu-items li:hover{
    background: var(--theme-color);
}
ul.nav-menu-items li{
    transition: .4s;
    padding: 0px 7px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
ul.nav-menu-items li:hover button,ul.nav-menu-items li:hover a{
    color: rgb(255, 255, 255) !important;
}
@media (min-width: 320px) and (max-width: 424px) {
    .mobile-nav ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0%;
        width: 100%;
        margin: 0;
        background: #ffffff;
        z-index: 50;
        padding: .5rem .8rem;
        overflow: hidden;
        box-shadow: 0 0 5px #7a7a7a;
    }
    .mobile-nav ul li a{
        font-size: 13px;
        color: #333333;
    }
}
@media (min-width: 425px) and (max-width: 575px) {
    .mobile-nav ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0%;
        width: 100%;
        margin: 0;
        background: #ffffff;
        z-index: 50;
        padding: .5rem .8rem;
        overflow: hidden;
        box-shadow: 0 0 5px #7a7a7a;
    }
    .mobile-nav ul li a{
        font-size: 13px;
        color: #333333;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .mobile-nav ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0%;
        width: 100%;
        margin: 0;
        background: #ffffff;
        z-index: 50;
        padding: .5rem .8rem;
        overflow: hidden;
        box-shadow: 0 0 5px #7a7a7a;
    }
    .mobile-nav ul li a{
        font-size: 13px;
        color: #333333;
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .mobile-nav ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0%;
        width: 100%;
        margin: 0;
        background: #ffffff;
        z-index: 50;
        padding: .5rem .8rem;
        overflow: hidden;
        box-shadow: 0 0 5px #7a7a7a;
    }
    .mobile-nav ul li a{
        font-size: 13px;
        color: #333333;
    }
}
@media (min-width: 991px) and (max-width: 1023px) {}
@media (min-width: 1024px) and (max-width: 1439px) {}

.sticky-nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 15;
}

.cart-hover-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0%;
    width: 300px;
    box-shadow: 0 0 5px #dadada;
    padding: 1rem;
    font-size: 14px;
}