.product-container {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: .7rem;
}
.product-container-section {
    background: var(--product-container-color);
}

@media (min-width: 320px) and (max-width: 424px) {
    .product-container {
        grid-template-columns: repeat(2,1fr);
    }
    .magnify-container img {
        height: 220px !important;
        width: 60% !important;
        margin: 0 auto;
    }
}
@media (min-width: 425px) and (max-width: 575px) {
    .product-container {
        grid-template-columns: repeat(2,1fr);
    }
    .magnify-container img {
        height: 220px !important;
        width: 60% !important;
        margin: 0 auto;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .product-container {
        grid-template-columns: repeat(3,1fr);
    }
}
@media (min-width: 768px) and (max-width: 990px) {
    .product-container {
        grid-template-columns: repeat(4,1fr);
    }
}
@media (min-width: 991px) and (max-width: 1023px) {
    .product-container {
        grid-template-columns: repeat(5,1fr);
    }
}
@media (min-width: 1024px) and (max-width: 1439px) {}
