.loader {
  animation: loader 3s linear infinite;
}
@keyframes loader {
  15% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}
