.theme-default .nivoSlider {
    position: relative;
    background: none !important;
    margin-bottom: 10px;
    box-shadow: none !important ;
}
.image-slice-box {
    position: static;
    width: 100%;
    height: 100%;
    cursor: pointer;
    box-shadow: none;

}

.theme-default .nivoSlider img {
    position: static;
    display: block;
    object-fit: cover;
    box-shadow: none;
}

@media (min-width: 320px) and (max-width: 424px) {
    .theme-default .nivoSlider img {
        height: 30vh !important;
    }
  
    
}
@media (min-width: 425px) and (max-width: 575px) {
    .theme-default .nivoSlider img {
        height: 40vh !important;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .theme-default .nivoSlider img {
        height: 45vh !important;
    } 
}
@media (min-width: 768px) and (max-width: 990px) {
    .theme-default .nivoSlider img {
        height: 48vh !important;
    }  
}
@media (min-width: 991px) and (max-width: 1023px) {
    .theme-default .nivoSlider img {
        height: 50vh !important;
    } 
}
@media (min-width: 1024px) and (max-width: 1439px) {}