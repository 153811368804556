.form-control:focus {
    box-shadow: none !important;
}
.logo-container h4{
    color: var(--primary-text-color);
}
ul.nav-icons-group li {
    border-radius: 50%;
    transition: .4s;
}
/* ul.nav-icons-group li:hover {
    background: #ddebff;
} */
.sticky-nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 15;
}
.nav-search{
    color: var(--theme-color);
}
.active-nav-menu {
    background: var(--bg-grey2);
    border-radius: 15px;
    /* padding: 4px 11px; */
    color: #28236c !important;
}
.blink-color{
    position: relative;
    border: 3px solid rgba(51, 255, 0, 0);

}
.cart:hover .cart-hover-item{
    visibility: visible;
    opacity: 1;
    z-index: 10;
}
.custom-badge {
    padding: 4px;
    border-radius: 50%;
    font-size: 10px;
    bottom: 69%;
    left: 13%;
    height: 17px;
    width: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-hover-item {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0%;
    width: 300px;
    box-shadow: 0 0 5px #dadada;
    padding: 1rem;
    font-size: 14px;
}
.blink-color::after{
    position: absolute;
    border-radius: 50%;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid rgb(51, 255, 0);
    animation: mymove 1s infinite;
    -webkit-animation: mymove 1s infinite;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
@keyframes mymove {
    0%   {
        top: 1%;
        left: 1%;
        border: 3px solid var(--color-1);
        height: 100%;
        width: 100%;
    }
    100% {
        top: -10%;
        left: -5%;
        height: 120%;
        width: 120%;
        border: 1px solid rgba(0, 0, 0, 0);
    }
  }
.dropdown-toggle::after {
    display: none !important;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.searchbar-layout {
    position: fixed;
    height: 0vh;
    width: 100%;
    background: #00000036;
    z-index: -1;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
}
.showlayout{
    height: 100vh;
    z-index: 10;
    visibility: visible;
    opacity: 1;
}
.searchbar-container {
    background: var(--bg-grey);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 0vh;
    visibility: hidden;
    opacity: 0;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
.showsearchbar{
    height: 17vh;
    z-index: 9999;
    visibility: visible;
    opacity: 1;
    background: white;
    /* border:1px solid white; */
}
.searchbar-cross {
    position: absolute;
    top: 0;
    right: 0;
    height: 35px;
    width: 35px;
    border: 1px solid var(--color-1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #505050;
    border-radius: 50%;
    margin: 2rem;
}
.nav-menu-container ul li {
    padding: 0px 0;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
.nav-menu-container ul li a {
    padding: 3px 7px;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
.nav-menu-container ul li:hover a{
    background: var(--color-1);
    border-radius: 15px;
    padding: 3px 7px;
    color: var(--bs-light) !important;
}


a.active-nav-menu {
    color: var(--bs-light) !important;
}

.nav-menu-layout {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    /* background: #0000003b; */
    z-index: 1;
}
.nav-icons-group svg{
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}
/* .nav-icons-group svg:hover{
    color: var(--color-1)
} */

@media (min-width: 320px) and (max-width: 424px) {
    .logo-container h4 {
        display: none;
    }
    .nav-menu-container {
        position: fixed;
        height: 100vh;
        width: 250px;
        top: 0;
        left: -250px;
        z-index: 20;
        padding: 1rem;
        transition: .3s;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
}
    .nav-menu-container ul li{
        padding:10px 0
    }
    .main-nav {
        padding: 10px;
    }
    .custom-badge {
        left: 53%;
    }
    .navBar {
        border-bottom: 1px solid rgb(228, 228, 228);
        background-color: rgb(255, 255, 255);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: none;
    }
}
@media (min-width: 425px) and (max-width: 575px) {
    .nav-menu-container {
        position: fixed;
        height: 100vh;
        width: 250px;
        top: 0;
        left: -250px;
        z-index: 20;
        padding: 1rem;
        transition: .3s;
    }
    .main-nav {
        padding: 10px;
    }
    .custom-badge {
        left: 53%;
    }
    .nav-menu-container ul li{
        padding:10px 0
    }

}
@media (min-width: 576px) and (max-width: 767px) {
    .nav-menu-container {
        position: fixed;
        height: 100vh;
        width: 250px;
        top: 0;
        left: -250px;
        z-index: 20;
        background: var(--light-color);
        padding: 1rem;
        transition: .3s;
    }
    .main-nav {
        padding: 5px 5px;
    }
    .nav-menu-container ul li{
        padding:10px 0
    }
}
@media (min-width: 768px) and (max-width: 990px) {}
@media (min-width: 991px) and (max-width: 1023px) {}
@media (min-width: 1024px) and (max-width: 1439px) {}