table th {
    font-weight: 700;
    font-size: 17px !important;
}
.order-history-section, table td, table th {
    color: var(--primary-text-color);
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
}
.custommer-info h5{
    font-size: 17px;
    line-height: 15px;
}
.custommer-info p{
    font-size: 14px;
    line-height: 15px;
}
.custommer-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}
.progressBar {
    height: 5px;
    width: 100%;
    background: rgb(0, 182, 9);
    border-radius: 10px;
    position: relative;
    margin-top: .7rem;
}
.progress-num {
    position: absolute;
    top: -19px;
    font-size: 14px;
}
