@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Qwitcher+Grypen:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Qwitcher+Grypen:wght@400;700&display=swap');
html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.poppin {
  font-family: "Poppins", sans-serif;
}
.grypen {
  font-family: "Qwitcher Grypen", cursive;
}
.roboto {
  font-family: "Roboto", "sans-serif";
}
.oswald{
  font-family: 'Oswald', sans-serif;
}
.left0 {
  left: 0 !important;
}
.shape {
  position: relative;
}
.shape::after {
  position: absolute;
  bottom: 100%;
  content: "";
  left: 2%;
  border-left: 15px solid rgba(255, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  border-bottom: 15px solid rgba(235, 234, 234, 0.925);
  border-top: 15px solid rgba(60, 255, 0, 0);
}



.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}