.footer2-container h5{
    font-size: 15px;
    color: var(--primary-text-color);
}   
.footer2-container span,.footer2-container a{
    font-size: 13px;
    color: var(--secondary-text-color);
}  
.footer_icon_hover{
    scale: 1.1;
    transition: .4s;
} 
.footer_icon_hover:hover{
    scale: 1;
    transition: .4s;
} 