.feature-banner-text {
    color: var(--primary-text-color);
}

.feature-banner-text span {
    color: var(--color-1);
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
    font-weight: 700;
}
.feature-banner-text h5 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-text-color);
}
.feature-banner-text p {
    font-size: 14px;
}

@media (min-width: 320px) and (max-width: 424px) {
    .feature-banner-text span {
    font-size: 2rem;
}
.feature-banner-text h5 {
    font-size: 1.3rem;
}
}
@media (min-width: 425px) and (max-width: 575px) {
    .feature-banner-text span {
    font-size: 2rem;
}
.feature-banner-text h5 {
    font-size: 1.3rem;
}
}
@media (min-width: 576px) and (max-width: 767px) {}
@media (min-width: 768px) and (max-width: 990px) {}
@media (min-width: 991px) and (max-width: 1023px) {}
@media (min-width: 1024px) and (max-width: 1439px) {}
