
 .config-container {
    position: fixed;
    bottom: 5%;
    right: 2%;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 100;
}
.theme-controller{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.back-to-top {
    font-size: 14px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: var(--theme-color);
    /* border: 1px solid gray; */
    white-space: nowrap;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.back-to-top svg {
    color: var(--color-1);
    animation: slide-up 1s linear infinite;
    -webkit-animation: slide-up 1s linear infinite;
}
@keyframes slide-up {
    0%{
        top: 5px;
    }
    100%{
        top: 15px;
    }
}
