.move{
    animation: move 1s linear infinite;
    rotate:25deg
}
@keyframes move {
    0%{
        scale:1.2
    }
    100%{
        scale:1
    }
}